import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

//const classes = useStyles();
  
function valuetext(value) {
    return `${value}"`;
    //console.log(value);
}

//const [value, setValue] = React.useState(30); 

export default class DiscreteSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.startValue
        };

        this.theme = createMuiTheme({
            palette: {
              primary: {
                light: '#484848',
                main: '#212121',
                dark: '#000000',
                contrastText: '#fff',
              },
              secondary: {
                light: '#ff8a50',
                main: '#ff5722',
                dark: '#c41c00',
                contrastText: '#000',
              },
            },
          });
    }

    handleSliderChange = (event, newValue) => {
        this.setState({value: newValue});
        this.props.onValueChange(newValue);
    };  

    render() {
        return (
            <div>
            <Typography id="discrete-slider" gutterBottom>
                {this.props.name + ": " + this.state.value + '"'}
            </Typography>
            <ThemeProvider theme={this.theme}>
                <Slider
                    defaultValue={this.props.startValue}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks={false}
                    min={this.props.minimum}
                    max={this.props.maximum}
                    onChange={this.handleSliderChange}  
                />
            </ThemeProvider>
            </div>
        );
    }
}