import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logoMark from '../images/Wood-And-Welds-Logomark.svg'
import cartIcon from '../images/CartIcon.svg';

class Header extends React.Component {
    render() {
        return(
            <header className="App-header">
                <div className="MenuButton">
                    <Link 
                        to="/"
                    >
                        <img 
                            src={logoMark}
                            width="30px"
                            alt="Menu"
                        />
                    </Link>
                </div>
                
                <div className="HeaderTitle">
                    {this.props.title}
                </div>
                <div className="CartIcon">
                <Link
                    
                    to="/Cart"
                >
                    <img 
                        src={cartIcon}
                        width="30px"
                        alt="Cart"
                    />
                </Link>
                </div>
                
            </header>
        )
    }
}

export default Header