import {React, useState} from 'react';
import {CartItems} from './CartItems';
import Header from './Header';
import Footer from './Footer';
import './Cart.css';
import { useShoppingCart } from 'use-shopping-cart';
import { loadStripe } from "@stripe/stripe-js";
import Button from '@material-ui/core/Button';

export function Cart() {
    const { totalPrice, cartCount, cartDetails } = useShoppingCart();
    const stripePromise = loadStripe("pk_live_51HgzfjLdyXRco3IChUEmx90FHW2uF8efjLgsLuZPiAK5qrnUzf4VYyCYGGDH3um5kR6d4tNfDpIRojqAMG7CNbWw00LdAwoxlU");
    const [clicked, setClicked] = useState(false);

    const handleCheckoutClick = async (event) => {
        setClicked(true);
        const stripe = await stripePromise;
        const response = await fetch("/create-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({items: cartDetails}),
        });
    
        const session = await response.json();
    
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
    
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          window.alert(result.error.message);
        }
      };

    return (
        <div>
            <Header title="Cart"/>
                {cartCount===0 ? 
                    <h2 className="EmptyCart">Your cart is empty.</h2>
                    :
                    <div className="CartContainer">
                        <div className="CartList">
                            <CartItems />
                        </div>
                        <div className="Subtotal">
                            <h3>Subtotal:</h3>
                            <h2>{'$'+totalPrice/100}</h2>
                        </div>
                        {clicked ? <h2>Loading...</h2>
                        :
                        <div className="WWStripeCheckout">
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCheckoutClick}
                          >Checkout</Button>
                        </div>
                        }
                    </div>}
            <Footer />
        </div>
    )
    
}