import React from 'react';
import './App.css';
import LaptopStand from './components/LaptopStand';
import Home from './components/Home';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Cart } from './components/Cart';
import { CheckoutReturn } from './components/CheckoutReturn';
import SideTable from './components/SideTable';
import WritingDesk from './components/WritingDesk';
import {Jobs} from './components/Jobs';
import {Contact} from './components/Contact';
import {About} from './components/About';
import GA from './components/GoogleAnalytics';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.theme = createMuiTheme({
      palette: {
        primary: {
          light: '#484848',
          main: '#212121',
          dark: '#000000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff8a50',
          main: '#ff5722',
          dark: '#c41c00',
          contrastText: '#000',
        },
      },
    });
    
  };

  render() {
    return (
      <Router>
        { GA.init() && <GA.RouteTracker /> }
        <ScrollToTop />
      <div className="App">
        <ThemeProvider theme={this.theme}>

            
            <Switch>
            
            <Route exact path="/" children={<Home />}></Route>
            <Route path="/LaptopStand" children={<LaptopStand />}></Route>
            <Route path="/SideTable" children={<SideTable />}></Route>
            <Route path="/WritingDesk" children={<WritingDesk />}></Route>
            <Route path="/Cart" children={<Cart />}></Route>
            <Route path="/Jobs" children={<Jobs />}></Route>
            <Route path="/Contact" children={<Contact />}></Route>
            <Route path="/About" children={<About />}></Route>
            <Route path="/CheckoutReturn" children={<CheckoutReturn />}></Route>
            </Switch>
          
        </ThemeProvider>
        
      </div>
      </Router>
    );
  }
}

export default App