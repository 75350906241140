import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import phoneScreen from '../images/PhoneScreen@2x.jpeg';
import prototype from '../images/LaptopStand_Orange.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LaptopStandRender from '../images/LaptopStand_Blue.png';
import SideTableRender from '../images/SideTable_Terracotta.png';
import LaptopStandRenderDesktop from '../images/LaptopStand_Blue_Desktop.png';
import SideTableRenderDesktop from '../images/SideTable_Terracotta_Desktop.png';
//import WritingDeskRender from '../images/WritingDesk_Mint.png';
import {Popover, Button} from '@material-ui/core';
import HubspotForm from 'react-hubspot-form';
import logo from '../images/Wood-And-Welds-Logo-Vertical.svg';
import USA from '../images/United-States.svg';
import {isMobile} from 'react-device-detect';

class Home extends React.Component {
    constructor(props) {
        super(props);
        
        this.myRef = React.createRef();

        this.theme = createMuiTheme({
          palette: {
            primary: {
              light: '#484848',
              main: '#212121',
              dark: '#000000',
              contrastText: '#fff',
            },
            secondary: {
              light: '#ff8a50',
              main: '#ff5722',
              dark: '#c41c00',
              contrastText: '#000',
            },
          },
        });

        this.state = {
            anchorEL: null,
            open: false,
            id: undefined
        }
        
      };

    componentDidMount =() => {
        const node = document.getElementById('ListButton');
        this.setState({
            anchorEL: node,
        }, () => {});
    }

    handleOpenModal = () => {
        this.setState({
            open: true,
            id: 'SignupPopover'
        }, () => {});
    }

    handleCloseModal = () => {
        this.setState({
            open: false,
            id: undefined
        }, () => {});
    };

    render() {
        return(
                
        <div className="HomePage">
            <ThemeProvider theme={this.theme}>
                <Header 
                    title="Wood & Welds"
                />
                <div className="CarouselWrapper">
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        transitionTime="300"
                        infiniteLoop={true}
                        autoPlay={true}
                        stopOnHover={true}
                        swipeable={true}
                        emulateTouch={true}
                        interval="5000"
                    >
                        <div>
                            {isMobile ? <img src={LaptopStandRender} alt="Laptop Stand"/>
                            :
                            <img src={LaptopStandRenderDesktop} alt="Laptop Stand"/>}
                            
                            <div className="legend">
                                <h2 className="CarouselTitle">Laptop Stand</h2>
                                <Link to="/LaptopStand">Customize</Link>
                            </div>
                        </div>
                        <div>
                            {isMobile ? <img src={SideTableRender} alt="Side Table"/>
                            :
                            <img src={SideTableRenderDesktop} alt="Side Table"/>}
                            
                            <div className="legend">
                                <h2 className="CarouselTitle">Side Table</h2>
                                <Link to="/SideTable">Customize</Link>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="Build">
                    <div className="HomeSection1">
                        <h1 className="BannerHeading Black">You customize it.</h1>
                        <img 
                            className="PhoneScreen"
                            src={phoneScreen} 
                            alt='Wood-and-Welds-phone-screen'
                            width='200px'
                        />
                    </div>
                    <div className="HomeSection2">
                        <h1 className="BannerHeading">We build it.</h1>
                        <img 
                            className="Prototype"
                            src={prototype} 
                            alt='Wood-and-Welds-Laptop-Stand'
                            width='300px'
                        />
                    </div>
                </div>
                
                <div className="MadeInUSA">
                    <h2>Designed and hand-built in the USA.</h2>
                    <img src={USA} width="100px" alt="United State of America"/>
                </div>
                <div className="HomeDivider">Features</div>
                <div className="Features">
                    <div className="DetailSection DetailOne">
                        <div className="DetailHeadingContainer">
                            <h2 className="DetailHeading">Powder coated steel</h2>
                        </div>
                    </div>
                    <div className="DetailSection DetailTwo">
                        <div className="DetailHeadingContainer">
                            <h2 className="DetailHeading">Solid wood</h2>
                        </div>
                    </div>
                    <div className="DetailSection DetailThree">
                        <div className="DetailHeadingContainer">
                            <h2 className="DetailHeading">Fully assembled</h2>
                        </div>
                    </div>
                </div>
                <div className="HomeDivider">Work from Home Collection</div>
                <div className="Products">
                <div className="ProductSection ProductOne">
                    <h3>Laptop Stand</h3>
                    
                        <div className="CustomizeButton">
                            <div className="LinkContainer">
                                <Link to="/LaptopStand">Customize</Link>
                            </div>
                        </div>
                    
                </div>
                <div className="ProductSection ProductTwo">
                    <h3>Side Table</h3>
                    <div className="CustomizeButton">
                        <div className="LinkContainer">
                            <Link to="/SideTable">Customize</Link>
                        </div>
                    </div>
                </div>
                <div className="ProductSection ProductThree">
                    <h3>Writing Desk</h3>
                    <div id="ListButton">
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => {
                                this.handleOpenModal();
                            }}
                        >
                            Join the List
                        </Button>
                    </div>
                    <Popover 
                        id={this.state.id}
                        open={this.state.open}
                        anchorReference={"none"}
                        onClose={this.handleCloseModal}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                            }}
                        anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                        }}
                    >
                        <div className="AddToListPopover">
                            <HubspotForm
                                    portalId="8857206"
                                    formId="76ee3f52-0a0e-4c14-a049-04fc6d1080d5"
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />
                        </div>
                    </Popover>
                </div>
                </div>
                

                <img 
                        className="VerticalLogo"
                        width='200px'
                        src={logo} 
                        alt="Wood & Welds Logo"
                    />
                <Footer />
            </ThemeProvider> 
        </div>
        );
    }
}

export default Home;