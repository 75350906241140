import React from 'react';
import Footer from './Footer';
import Header from './Header';
import './About.css';
import HubspotForm from 'react-hubspot-form'
import logo from '../images/Wood-And-Welds-Logo-Vertical.svg';

export function About()  {

    return(
        <div>
            
            <Header title="About" />
                <div className="AboutContainer">
                    <div className="AboutBlurb">
                    <p>Wood & Welds is on a mission to simplify the customized furniture industry. Our goal is to create intuitive and fun experiences that allow our customers to get exactly what they want.</p>
                    <p>At our headquarters in Minnesota, we strive to continuously improve our sustainability and efficiency, because we care about the planet, our employees, and our customers.</p>

                    </div>
                    <h2>Want Wood & Welds news?</h2>
                    <h4>Sign up for our newsletter below.</h4>
                    <div className="SubscribeForm">
                        <HubspotForm
                        portalId="8857206"
                        formId="1f0e94cf-b19f-4cd5-90cb-97de1ad5eeae"
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />

                    </div>

                    <h4>For all other inquiries please contact us at:</h4>
                    <h3>info@woodandwelds.com</h3>
                </div>  
                <img 
                        className="VerticalLogo"
                        width='200px'
                        src={logo} 
                        alt="Wood & Welds Logo"
                    />
            <Footer />
        </div>
    )
}