import React from 'react';
import Footer from './Footer';
import Header from './Header';
import './Jobs.css';

export function Jobs()  {
    return(
        <div>
            <Header title="Jobs" />
                <div className="NoJobs">
                    <h4>There are currently no posted positions.</h4>
                    <p>We are always willing to take a look at a resume or portfolio if you are interested in becoming part of the team.</p>
                    <h3>jobs@woodandwelds.com</h3>
                </div>
            <Footer />
        </div>
    )
}