import { useShoppingCart } from 'use-shopping-cart';

export function CartItems() {
  const {
    cartDetails,
    decrementItem,
    incrementItem,
    removeItem
  } = useShoppingCart()

  const cart = []
  // Note: Object.keys().map() takes 2x as long as a for-in loop
  for (const sku in cartDetails) {
    const cartEntry = cartDetails[sku]

    // all of your basic product data still exists (i.e. name, image, price)
    cart.push(
      <article key={cartEntry.sku} className="CartListItem">
        {/* image here */}
        <div className="CartItemDetails">
            <h3>{cartEntry.name}</h3>
            <p>{'Dimensions: '+cartEntry.width+'in. x '+cartEntry.depth+'in. x '+cartEntry.height+'in.'}</p>
            <p>{'Shelf: '+(cartEntry.shelf ? 'Yes' : 'No')}</p>
            {cartEntry.shelf ? (<p>{'Shelf Height: '+cartEntry.shelfHeight+'in.'}</p>):null}
            <p>{'Wood Type: '+cartEntry.wood}</p>
            <p>{'Base Color: '+cartEntry.baseColor}</p>
        </div>
        
        {/* formatted total price of that item */}
        <div className="CartPriceQty">
            <p className="CartItemPrice">{'$'+cartEntry.price/100}</p>
            {/* What if we want to remove one of the item... or add one */}
            <div className="QtyControls">
                <button 
                className="QtyMinus"
                onClick={() => decrementItem(cartEntry.sku)}
                aria-label={`Remove one ${cartEntry.name} from your cart`}
                >
                -
                </button>
                <p className="Qty">{cartEntry.quantity}</p>
                <button
                className="QtyPlus"
                onClick={() => incrementItem(cartEntry.sku)}
                aria-label={`Add one ${cartEntry.name} to your cart`}
                >
                +
                </button>
            </div>
            <button 
            className="RemoveButton"
            onClick={() => removeItem(cartEntry.sku)}
            aria-label={`Remove all ${cartEntry.name} from your cart`}
            >
            Remove
            </button>
        </div>
        

        {/* What if we don't want this product at all */}
        
      </article>
    )
  }

  return cart
}