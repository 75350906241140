import React from 'react';
import { useShoppingCart } from 'use-shopping-cart'
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {Popover} from '@material-ui/core';
import "./Product.css";

export function Product({ product }) {
    const { addItem } = useShoppingCart();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenModal = () => {
        setAnchorEl(document.getElementById("AddToCartButton"));
    };

    const handleCloseModal = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'AddToCartPopover' : undefined;

    var date = new Date();
    
    var now = date.getFullYear().toString() + date.getMonth().toString() + date.getDate().toString() + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() + date.getMilliseconds().toString();

    product.sku = now;

  /* A helper function that turns the price into a readable format
  const price = formatCurrencyString({
    value: product.price,
    currency: product.currency,
    language: navigator.language
  })
  */

  return (
      <div>
            <Button 
            id="AddToCartButton"
            variant="contained" 
            color="primary"
            onClick={() => {
                addItem(product);
                handleOpenModal();
            }}
            aria-label={`Add ${product.name} to your cart`}
        >
            Add to Cart
        </Button>
            <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseModal}
                anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
            >
                <div className="AddToCartPopoverContent">
                    <h3>Item added to your cart.</h3>
                    <div className="ProductLinkContainer">
                        <Link to="/Cart">View Cart</Link>
                    </div>
                    <div className="ProductLinkContainer">
                        <Link to="/">Keep Shopping</Link>
                    </div>
                </div>
            </Popover>
      </div>  
  )
}