import {React, useState, useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import './CheckoutReturn.css';
import {useShoppingCart} from 'use-shopping-cart';

export function CheckoutReturn() {
    const { clearCart } = useShoppingCart();

    const Message = ({ message }) => (
        <section>
          <h2>{message}</h2>
          <p>{subMessage}</p>
        </section>
    );

    const [message, setMessage] = useState("");
    const [subMessage, setSubMessage] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed!");
            setSubMessage("Thank you for shopping with us. You will receive an email confirmation shortly.");
            clearCart();
        }

        if (query.get("canceled")) {
            setMessage("Order canceled");
            setMessage(
            "Continue to shop around and checkout when you're ready."
        );
        }
    }, []);


    return (
        <div>
            <Header title="Checkout"/>
                {message ? (<div className="Message"><Message message={message} /></div>)
                :
                (
                    <div className="CheckoutError">
                        <p>Uh oh! Something went wrong on our end.</p>
                    </div>
                )}
            <Footer />
        </div>
    )
    
}