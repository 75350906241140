import React from 'react';
import Footer from './Footer';
import Header from './Header';
import './Contact.css';
import HubspotForm from 'react-hubspot-form'
import logo from '../images/Wood-And-Welds-Logo-Vertical.svg';

export function Contact()  {

    return(
        <div>
            
            <Header title="Contact" />
                <div className="ContactContainer">
                    <h2>Need support?</h2>
                    <p>We're here to help. Send us a note and someone from our team will get back to you within 24 hours.</p>
                    <h3 className="SupportEmail">support@woodandwelds.com</h3>
                    <h2>Want Wood & Welds news?</h2>
                    <h4>Sign up for our newsletter below.</h4>
                    <HubspotForm
                        portalId="8857206"
                        formId="1f0e94cf-b19f-4cd5-90cb-97de1ad5eeae"
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                    <h4>For all other inquiries please contact us at:</h4>
                    <h3>info@woodandwelds.com</h3>
                </div>  
                <img 
                        className="VerticalLogo"
                        width='200px'
                        src={logo} 
                        alt="Wood & Welds Logo"
                    />
            <Footer />
        </div>
    )
}