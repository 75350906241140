import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import facebookIcon from '../images/facebook-icon.svg'
import instaIcon from '../images/instagram-icon.svg'
import linkedIcon from '../images/linkedIn-icon.svg'

class Footer extends React.Component {
    render() {
        return(
            <div className="Footer">
                    <div className="FooterBottom">
                        <div className="FooterLinks">
                            <Link to="/About">About</Link>
                            <Link to="/Jobs">Jobs</Link>
                            <Link to="/Contact">Contact</Link>
                        </div>
                        <div className="SocialLinks">
                            <a href="https://www.facebook.com/WoodAndWelds/" target="_blank" rel="noreferrer">
                                <img width="30px" src={facebookIcon} alt="Facebook Link"/>
                            </a>
                            <a href="https://www.instagram.com/woodandwelds/" target="_blank" rel="noreferrer">
                                <img width="30px" src={instaIcon} alt="Instagram Link"/>
                            </a> 
                            <a href="https://www.linkedin.com/company/wood-and-welds/" target="_blank" rel="noreferrer">
                                <img width="30px" src={linkedIcon} alt="LinkedIn Link"/>
                            </a> 
                        </div>
                        <div className="Copyright">© COPYRIGHT 2020 BY WOOD & WELDS LLC</div>

                    </div>
                </div>
        )
    }
}

export default Footer